import superagent from 'superagent';
import superagentAbsolute from 'superagent-absolute';
import notificationHandler from './notificationHandler';
import store from '../store/index';

// const rawToken = localStorage.getItem('lucid-token');
// // eslint-disable-next-line operator-linebreak
// const token =
//   rawToken != null ? `${rawToken}`.substr(10, rawToken.length - 12) : '';

// let agent = superagent.agent();
// agent.on('error', (err) => {
//   notificationHandler(err);
// });

// agent = agent.set('Content-Type', 'application/json');
// agent = agent.set('authorization', `Bearer ${token}`);
// agent = agent.type('json');

// const request = superagentAbsolute(agent)(`${process.env.REACT_APP_API_LINK}`);

export default function getAgentInstance(forForm = false) {
  let agent = superagent.agent();
  agent.on('error', (err) => {
    notificationHandler(err);
  });

  if (!forForm) {
    agent = agent.set('Content-Type', 'application/json');
    agent = agent.type('json');
  }
  agent = agent.set('authorization', `Bearer ${store.tokenStore.value}`);

  const request = superagentAbsolute(agent)(
    `${process.env.REACT_APP_API_LINK}`
  );
  return request;
}
