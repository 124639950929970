import React, { Suspense } from "react";

import { observer, inject } from "mobx-react";
import { Layout, Menu, Button, Col, Popover, Avatar, List } from "antd";
import {
  HeartFilled,
  MenuOutlined,
  ProjectFilled,
  FileImageFilled,
  FileTextFilled,
  ContactsFilled,
  HomeOutlined,
  CodepenCircleOutlined,
  SoundOutlined,
  VideoCameraOutlined,
  UpSquareOutlined,
  DownSquareOutlined,
  TrophyOutlined,
  PlayCircleOutlined,
  AudioOutlined,
  DatabaseOutlined,
} from "@ant-design/icons";

import { Switch, Route, Link, withRouter } from "react-router-dom";
import Loading from "../basic/Loading";
import routes from "./dashboardRoutes";
import largeLogo from "../../assets/images/logo.svg";
import smallLogo from "../../assets/images/logo2.svg";

import "./dashboard.css";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

@observer
@inject("userStore", "tokenStore", "sectionsStore")
class Dashboard extends React.Component {
  constructor() {
    super();
    this.initialState = () => ({
      collapsed: false,
      expandedWithToggler: true,
      imageId: "",
    });
    this.state = this.initialState();
    this.onCollapse = (collapsed) => {
      this.setState({ collapsed });
    };
    this.routeClicked = (history, to) => {
      history.push(to);
    };
    this.toggleSidebar = () => {
      this.setState((prevState) => ({
        collapsed: !prevState.collapsed,
        expandedWithToggler: true,
      }));
    };
    this.expandSidebar = () => {
      const { collapsed } = this.state;
      if (collapsed) {
        this.setState({ collapsed: false, expandedWithToggler: false });
      }
    };
    this.collapseSidebar = () => {
      const { expandedWithToggler } = this.state;
      if (!expandedWithToggler) this.setState({ collapsed: true });
    };
    this.logout = () => {
      Promise.all([
        // eslint-disable-next-line react/destructuring-assignment
        this.props.userStore.clear(),
        // eslint-disable-next-line react/destructuring-assignment
        this.props.tokenStore.clear(),
        // eslint-disable-next-line react/destructuring-assignment
        this.props.sectionsStore.clear(),
      ]).then(() => { });
    };
  }

  render() {
    const { collapsed, imageId } = this.state;
    const { location } = this.props;
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <style>
          {` .ant-menu-item-selected {
        background-color: #1f2533 !important;
      }

      `}
        </style>
        <Sider
          theme="dark"
          onMouseEnter={this.expandSidebar}
          onMouseLeave={this.collapseSidebar}
          collapsed={collapsed}
          onCollapse={this.onCollapse}
          style={{
            height: "100vh",
            left: 0,
            position: "fixed",
            overflow: "auto",
            background: "#2d3546",
          }}
          width={240}
        >
          <div
            className="logo"
            style={{
              minHeight: 70,
              textAlign: "center",
              paddingTop: 20,
              background: "#1f2532",
            }}
          >
            {collapsed ? (
              <img
                alt="lucid-logo"
                style={{ width: 45, marginBottom: 5 }}
                src={smallLogo}
              />
            ) : (
              <img alt="lucid-logo" style={{ width: 140 }} src={largeLogo} />
            )}
          </div>
          <Menu
            theme="dark"
            selectedKeys={[location.pathname]}
            mode="inline"
            style={{ background: "#2d3646", marginTop: 35 }}
          >
            <SubMenu key="sub1" icon={<HomeOutlined />} title="Home Page">
              <Menu.ItemGroup style={{ background: "rgb(31, 37, 50)" }}>
                <Menu.Item key="/HomePage">
                  <ProjectFilled />
                  <span>HITEX IN NUMBERS</span>
                  <Link to="/HomePage" />
                </Menu.Item>
                <Menu.Item key="/hitex">
                  <ProjectFilled />
                  <span>Hitex About</span>
                  <Link to="/hitex" />
                </Menu.Item>
                <Menu.Item key="/vipSpeaker">
                  <ProjectFilled />
                  <span>Hitex Quotes</span>
                  <Link to="/vipSpeaker" />
                </Menu.Item>
                <Menu.Item key="/fromHitexHeader">
                  <ProjectFilled />
                  <span>Hitex Conference Header</span>
                  <Link to="/fromHitexHeader" />
                </Menu.Item>
                <Menu.Item key="/fromHitexBody">
                  <ProjectFilled />
                  <span>Hitex Conference Body</span>
                  <Link to="/fromHitexBody" />
                </Menu.Item>

                <Menu.Item key="/fromHitexVedio">
                  <ProjectFilled />
                  <span>Youtube Vedio</span>
                  <Link to="/fromHitexVedio" />
                </Menu.Item>

                <Menu.Item key="/meetHitexSpeakers">
                  <ProjectFilled />
                  <span>Meet Hitex Speakers</span>
                  <Link to="/meetHitexSpeakers" />
                </Menu.Item>
                <Menu.Item key="/futureServiceBody">
                  <ProjectFilled />
                  <span>HFS Winners</span>
                  <Link to="/futureServiceBody" />
                </Menu.Item>
                <Menu.Item key="/category">
                  <ProjectFilled />
                  <span>Category</span>
                  <Link to="/category" />
                </Menu.Item>
                <Menu.Item key="/exhibitorLogo">
                  <ProjectFilled />
                  <span>Exhibitors</span>
                  <Link to="/exhibitorLogo" />
                </Menu.Item>
                <Menu.Item key="/participant">
                  <ProjectFilled />
                  <span>Participants</span>
                  <Link to="/participant" />
                </Menu.Item>

              </Menu.ItemGroup>
            </SubMenu>

            <SubMenu key="sub2" icon={<SoundOutlined />} title="Confrence">
              <SubMenu key="sub55" icon={<SoundOutlined />} title="Confrence Info">
                <Menu.ItemGroup>
                  <Menu.Item key="/conferenceHeader">
                    <ProjectFilled />
                    <span>Conference Header</span>
                    <Link to="/conferenceHeader" />
                  </Menu.Item>
                  <Menu.Item key="/confrance">
                    <ProjectFilled />
                    <span>Conference Body</span>
                    <Link to="/confrance" />
                  </Menu.Item>
                  <Menu.Item key="/conference-agenda">
                    <ProjectFilled />
                    <span>Conference Agenda</span>
                    <Link to="/conference-agenda" />
                  </Menu.Item>
                  <Menu.Item key="/conference-agenda-days">
                    <ProjectFilled />
                    <span>Conference Days</span>
                    <Link to="/conference-agenda-days" />
                  </Menu.Item>
                </Menu.ItemGroup>

              </SubMenu>
              <SubMenu key="sub66" icon={<ProjectFilled />} title="Panle Information" >
                <Menu.ItemGroup>
                  <Menu.Item key="/panelList">
                    <ProjectFilled />
                    <span>Panel Header</span>
                    <Link to="/panelList" />
                  </Menu.Item>
                  <Menu.Item key="/panelListBody">
                    <ProjectFilled />
                    <span>Panel Body</span>
                    <Link to="/panelListBody" />
                  </Menu.Item>
                  <Menu.Item key="/panelListBodySpeaker">
                    <ProjectFilled />
                    <span>Panel Body Speaker</span>
                    <Link to="/panelListBodySpeaker" />
                  </Menu.Item>
                </Menu.ItemGroup>
              </SubMenu>

              <SubMenu key="sub3" icon={<AudioOutlined />} title="Speakers Page">
                <Menu.ItemGroup>
                  <Menu.Item key="/speakerHeader">
                    <ProjectFilled />
                    <span>Speaker Header</span>
                    <Link to="/speakerHeader" />
                  </Menu.Item>
                  <Menu.Item key="/speakerBody">
                    <ProjectFilled />
                    <span>Speaker Body</span>
                    <Link to="/speakerBody" />
                  </Menu.Item>
                </Menu.ItemGroup>
              </SubMenu>
            </SubMenu>
            <SubMenu
              key="sub8"
              icon={<DatabaseOutlined />}
              title="Exhibition"
            >
              <Menu.ItemGroup>
                <Menu.Item key="/aboutExhibitor">
                  <ProjectFilled />
                  <span>About Exhibitor</span>
                  <Link to="/aboutExhibitor" />
                </Menu.Item>
                <Menu.Item key="/reasons">
                  <ProjectFilled />
                  <span>Exhibitor Reasons</span>
                  <Link to="/reasons" />
                </Menu.Item>
                <Menu.Item key="/testimonials">
                  <ProjectFilled />
                  <span>Exhibitor Testimonials</span>
                  <Link to="/testimonials" />
                </Menu.Item>
                <Menu.Item key="/sponsor">
                  <ProjectFilled />
                  <span>Sponsor</span>
                  <Link to="/sponsor" />
                </Menu.Item>
                <Menu.Item key="/logos">
                  <ProjectFilled />
                  <span>Exhibitor List</span>
                  <Link to="/logos" />
                </Menu.Item>
              </Menu.ItemGroup>
            </SubMenu>
            <SubMenu
              key="sub6"
              icon={<TrophyOutlined />}
              title="Hitex Future Stars"
            >
              <Menu.ItemGroup>
                <Menu.Item key="/hfs">
                  <ProjectFilled />
                  <span>Hitex Future Star</span>
                  <Link to="/hfs" />
                </Menu.Item>
                <Menu.Item key="/futureServiceHeader">
                  <ProjectFilled />
                  <span>Hitex Service Header</span>
                  <Link to="/futureServiceHeader" />
                </Menu.Item>
                <Menu.Item key="/futureServiceBody">
                  <ProjectFilled />
                  <span>Hitex Service Winners</span>
                  <Link to="/futureServiceBody" />
                </Menu.Item>
                <Menu.Item key="/projects">
                  <ProjectFilled />
                  <span>Projects</span>
                  <Link to="/projects" />
                </Menu.Item>
              </Menu.ItemGroup>
            </SubMenu>
            <SubMenu
              key="sub7"
              icon={<VideoCameraOutlined />}
              title="Other"
            >
              <SubMenu key="sub99" title="Entertainment" icon={<CodepenCircleOutlined />}>
                <Menu.ItemGroup>
                  <Menu.Item key="/gameZone">
                    <ProjectFilled />
                    <span>Game Zone</span>
                    <Link to="/gameZone" />
                  </Menu.Item>

                  <Menu.Item key="/compition">
                    <ProjectFilled />
                    <span>Competition</span>
                    <Link to="/compition" />
                  </Menu.Item>

                  <Menu.Item key="/winner">
                    <ProjectFilled />
                    <span>Winner Game</span>
                    <Link to="/winner" />
                  </Menu.Item>

                  <Menu.Item key="/galary">
                    <ProjectFilled />
                    <span>Gallery</span>
                    <Link to="/galary" />
                  </Menu.Item>
                </Menu.ItemGroup>
              </SubMenu>
              <SubMenu
                key="sub5"
                icon={<DownSquareOutlined />}
                title="Visit Page"
              >
                <Menu.ItemGroup>
                  <Menu.Item key="/visitHeader">
                    <ProjectFilled />
                    <span>Visit Header</span>
                    <Link to="/visitHeader" />
                  </Menu.Item>
                  <Menu.Item key="/visitBody">
                    <ProjectFilled />
                    <span>Visit Body</span>
                    <Link to="/visitBody" />
                  </Menu.Item>
                </Menu.ItemGroup>
              </SubMenu>
              <Menu.Item key="/titleSeo">
                <ProjectFilled />
                <span>Title Seo</span>
                <Link to="/titleSeo" />
              </Menu.Item>
            </SubMenu>
            {/* <SubMenu
              key="sub2"
              icon={<SoundOutlined />}
              title="About Confrenece Page"
            >
              <Menu.ItemGroup>







              </Menu.ItemGroup>
            </SubMenu> */}
            {/* <SubMenu
              key="sub22"
              icon={<SoundOutlined />}
              title="Confrenece Agenda"
            >
              <Menu.ItemGroup>

              </Menu.ItemGroup>
            </SubMenu> */}

            {/* <SubMenu key="sub3" icon={<AudioOutlined />} title="Speakers Page">
              <Menu.ItemGroup>
                <Menu.Item key="/speakerHeader">
                  <ProjectFilled />
                  <span>Speaker Header</span>
                  <Link to="/speakerHeader" />
                </Menu.Item>
                <Menu.Item key="/speakerBody">
                  <ProjectFilled />
                  <span>Speaker Body</span>
                  <Link to="/speakerBody" />
                </Menu.Item>
              </Menu.ItemGroup>
            </SubMenu> */}

            <SubMenu key="sub4" icon={<UpSquareOutlined />} title="About Page">
              <Menu.ItemGroup>
                <Menu.Item key="/about">
                  <ProjectFilled />
                  <span>About Page</span>
                  <Link to="/about" />
                </Menu.Item>
              </Menu.ItemGroup>
            </SubMenu>






            {/*
            <SubMenu
              key="sub10"
              icon={<TrophyOutlined />}
              title="HFS winners Page"
            >
              <Menu.ItemGroup>
                <Menu.Item key="/futureServiceHeader">
                  <ProjectFilled />
                  <span>Hitex Service Header</span>
                  <Link to="/futureServiceHeader" />
                </Menu.Item>

                <Menu.Item key="/futureServiceBody">
                  <ProjectFilled />
                  <span>Hitex Service winners</span>
                  <Link to="/futureServiceBody" />
                </Menu.Item>
              </Menu.ItemGroup>
            </SubMenu> */}

            {/* <SubMenu
              key="sub11"
              icon={<UpSquareOutlined />}
              title="Contact us Page"
            >
              <Menu.ItemGroup>
                <Menu.Item key="/contactus">
                  <ProjectFilled />
                  <span>Contact Us</span>
                  <Link to="/contactus" />
                </Menu.Item>
              </Menu.ItemGroup>
            </SubMenu> */}


            {/* <Menu.Item key="/sector">
              <ProjectFilled />
              <span>Sector</span>
              <Link to="/sector" />
            </Menu.Item> */}
            {/* <Menu.Item key="/city">
              <ProjectFilled />
              <span>City</span>
              <Link to="/city" />
            </Menu.Item> */}
            {/* <Menu.Item key="/topics">
              <ProjectFilled />
              <span>Topics</span>
              <Link to="/topics" />
            </Menu.Item> */}
            {/* <Menu.Item key="/company">
              <ProjectFilled />
              <span>Company</span>
              <Link to="/company" />
            </Menu.Item> */}
            {/* <Menu.Item key="/panel-form-guest">
              <ProjectFilled />
              <span>Panel Form Guest</span>
              <Link to="/panel-form-guest" />
            </Menu.Item>
            <Menu.Item key="/panels">
              <ProjectFilled />
              <span>Panel titles</span>
              <Link to="/panels" />
            </Menu.Item> */}
            {/* <Menu.Item key="/form-company">
              <ProjectFilled />
              <span>Company Forms</span>
              <Link to="/form-company" />
            </Menu.Item> */}
            {/* <Menu.Item key="/country">
              <ProjectFilled />
              <span>Country</span>
              <Link to="/country" />
            </Menu.Item> */}

            {/* <Menu.Item key="/gallery">
        <FileImageFilled />
        <span>Gallery</span>
        <Link to="/gallery" />
      </Menu.Item> */}

            {/* <Menu.Item key="/inventories">
        <ApartmentOutlined />
        <span>Inventories</span>
        <Link to="/inventories" />
      </Menu.Item> */}
          </Menu>
        </Sider>
        <Layout
          className="site-layout"
          style={{
            transition: "all 0.2s",
            marginLeft: collapsed ? 80 : 240,
          }}
        >
          <Header
            className="site-layout-background"
            style={{ padding: 0, display: "inherit" }}
          >
            <Col span={1} style={{ textAlign: "center" }}>
              <Button
                type="link"
                icon={
                  <MenuOutlined style={{ fontSize: 15, color: "#1f2532" }} />
                }
                onClick={this.toggleSidebar}
              />
            </Col>
            <Col offset={21} span={2}>
              <Popover
                content={
                  <List>
                    <List.Item>
                      <a href="# " onClick={this.logout}>
                        Logout
                      </a>
                    </List.Item>
                  </List>
                }
                trigger="hover"
                // visible={visible}
                // onVisibleChange={handleVisibleChange}
                arrowPointAtCenter
                placement="bottomLeft"
              >
                <Avatar
                  src={`https://www.pngkey.com/png/full/114-1149878_setting-user-avatar-in-specific-size-without-breaking.png`}
                />
              </Popover>
            </Col>
          </Header>
          <Content style={{ margin: "16px 16px", marginBottom: 0 }}>
            <div
              className="site-layout-background"
              style={{ padding: 24, minHeight: 500 }}
            >
              <Suspense fallback={<Loading />}>
                <Switch>
                  {routes.map((route) => (
                    <Route
                      exact={route.exact}
                      key={route.key}
                      path={`${route.path}`}
                    >
                      <route.component />
                    </Route>
                  ))}
                </Switch>
              </Suspense>
            </div>
          </Content>
          {/* <Footer style={{ textAlign: 'center' }}>
      <span>Developed with </span>
      <HeartFilled />
      By&nbsp;
      <a href="https://lucid-source.com" title="Lucid Source">
        Lucid Source
      </a>
    </Footer> */}
        </Layout>
      </Layout>
    );
  }
}

export default withRouter((props) => <Dashboard {...props} />);
