import React from "react";
import { Row, Col, Card, Typography } from "antd";
import { HeartFilled } from "@ant-design/icons";
import { observer, inject } from "mobx-react";
import sideImage from "../../assets/images/bot2.png";
import Login from "./Login";

@inject("userStore", "tokenStore", "sectionsStore")
@observer
class LoginManager extends React.Component {
  constructor() {
    super();
    this.initialState = () => ({});

    this.state = this.initialState();
  }

  render() {
    const { tokenStore, to } = this.props;
    if (`${tokenStore.value}`.trim() !== "") {
      return to;
    }
    return (
      <Row
        style={{ position: "absolute", height: "100%", width: "100%" }}
        type="flex"
        align="middle"
      >

        <div style={{width:'50%', height: '100%', background: '#262c4a', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Col
            style={{
              height: "50%",
              width: "50%",
              backgroundImage: `url('${sideImage}')`,
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat"
            }}
            span={15}
          >
          </Col>

        </div>
          
        <Col span={9} offset={0}>
          <Row>
            <Col span={24}>
              <Typography.Title
                align="center"
                color="primary"
                style={{ color: "#00008b", letterSpacing: "1.5px" }}
              >
                <span style={{color:'#e3454b', fontSize: "4rem"}}>HITEX</span>
              </Typography.Title>
            </Col>
            <Col span={24}>
              <Card bordered={false}>
                <Login />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default LoginManager;
