import { lazy } from "react";

const options = [
  {
    key: Math.random(),
    path: "/",
    component: lazy(() => import("../landing/land")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/about",
    component: lazy(() => import("../about/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/aboutExhibitor",
    component: lazy(() => import("../aboutExhibitorPage/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/category",
    component: lazy(() => import("../category/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/compition",
    component: lazy(() => import("../competition/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/vipSpeaker",
    component: lazy(() => import("../vipSpeaker/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/fromHitexVedio",
    component: lazy(() => import("../fromHitexVedio/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/exhibitorLogo",
    component: lazy(() => import("../exhibitorLogo/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/conferenceHeader",
    component: lazy(() => import("../conferenceHeader/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/fromHitexBody",
    component: lazy(() => import("../fromHitexBody/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/fromHitexHeader",
    component: lazy(() => import("../fromHitexHeader/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/futureServiceBody",
    component: lazy(() => import("../futureServiceBody/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/futureServiceHeader",
    component: lazy(() => import("../futureServiceHeader/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/gameZone",
    component: lazy(() => import("../gameZone/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/hfs",
    component: lazy(() => import("../hfs/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/hitex",
    component: lazy(() => import("../hitexUnderLanding/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/logos",
    component: lazy(() => import("../exhibitorList/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/reasons",
    component: lazy(() => import("../exhibitorReasons/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/testimonials",
    component: lazy(() => import("../exhibitorTestimonials/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/galary",
    component: lazy(() => import("../gallery/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/meetHitexSpeakers",
    component: lazy(() => import("../meetHitexSpeakers/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/panelList",
    component: lazy(() => import("../panellist/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/panelListBody",
    component: lazy(() => import("../panellistBody/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/panelListBodySpeaker",
    component: lazy(() => import("../panellistBodySpeaker/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/participant",
    component: lazy(() => import("../participant/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/projects",
    component: lazy(() => import("../projects/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/speakerBody",
    component: lazy(() => import("../speakerBody/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/speakerHeader",
    component: lazy(() => import("../speakerHeader/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/titleSeo",
    component: lazy(() => import("../titleSeo/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/sector",
    component: lazy(() => import("../sector/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/city",
    component: lazy(() => import("../city/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/topics",
    component: lazy(() => import("../topics/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/company",
    component: lazy(() => import("../company/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/form-company/:id",
    component: lazy(() => import("../formCompany/List")),
    exact: true,
    render: false,
  },
  {
    key: Math.random(),
    path: "/sponsor",
    component: lazy(() => import("../sponsor/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/panel-form-guest",
    component: lazy(() => import("../panelFormGuest/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/panels",
    component: lazy(() => import("../panels/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/country",
    component: lazy(() => import("../country/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/visitBody",
    component: lazy(() => import("../visitBody/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/visitHeader",
    component: lazy(() => import("../visitHeader/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/winner",
    component: lazy(() => import("../winnerGame/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/contactus",
    component: lazy(() => import("../contactUs/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/HomePage",
    component: lazy(() => import("../landingPage/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/confrance",
    component: lazy(() => import("../conference/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/conference-agenda",
    component: lazy(() => import("../conference-agenda/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/conference-agenda-days",
    component: lazy(() => import("../conference-agenda-days/List")),
    exact: true,
  },
];
export default options;
